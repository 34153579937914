import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../../utils/axiosClient';
import { Buffer } from 'buffer';
import '../../App.css';

function AuthLoader() {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData(token) {
      const res = await axiosClient.get('/users/details', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      const data = res.data;
      if (data.success && data) {
        localStorage.setItem('HALEON-REWARDS_USER_TOKEN', token)
        navigate('/home', { state: { from: 'login' } });
      } else {
        navigate('/', { replace: true });
      }
    }
    if (token) {
      const decodeToken = Buffer.from(token, 'base64').toString('utf-8');
      fetchData(decodeToken);
    }
  });

  console.log(token);

  return (
    <div 
      style={{ 
        alignItems: 'center' ,
        justifyContent: 'center',
        display: 'flex',  
        backgroundColor: '#554e4e9e',
        height: '100vh'
      }}
    >
      <div className="loader" ></div>
    </div>
  );
}

export default AuthLoader;
