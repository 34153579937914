import './App.css';

import { ADMIN, RETAILER, SALE_REP, USER } from './enums';
import {
	BrowserRouter,
	Navigate,
	Route,
	Routes,
	useNavigate,
} from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import React, { Suspense, lazy, useEffect, useState } from 'react';

import AddUpdateAddress from './view/Profile/AddUpdateAddress';
import AddressMainPage from './view/Profile/AddressMainPage';
import AdminLogin from './view/AdminPortal/Welcome/Login';
import ArticlePage from './view/Home/Article';
import ArticlesManagement from './view/AdminPortal/Settings/ArticlesManagement';
import AuthRedirect from './utilities/AuthRedirect';
import CartInfoPage from './view/Cart/CartInfo';
import CartSuccessPage from './view/Cart/SummarySuccess';
// import BadgesPage from './view/Profile/Badges';
// import ExplorerBadgePage from './view/Profile/ExplorerBadge';
import CartSummaryPage from './view/Cart/CartSummary';
import DeliveryAddressPage from './view/Cart/DeliveryAddress';
import DeliveryManagementReport from './view/AdminPortal/Report/DeliveryReport';
import GetStartedPage from './view/Onboarding/GetStarted';
import HelpCenter from './view/Profile/HelpCenter';
import Home from './view/Home/Home';
import LoadingPage from './view/Onboarding/Loading';
import LoginPage from './view/Register/LoginPage';
import LoginVerify from './view/Home/LoginVerify';
import OperatorLoadingPage from './view/Operator/OpLoading';
import OperatorLoginPage from './view/Operator/OpLogin';
import OperatorVerifyPage from './view/Operator/OpVerify';
import ParticipatingStoresPage from './view/Home/ParticipatingStores';
import PaymentHistory from './view/AdminPortal/Retailer/PaymentHistory';
import PersonalDetails from './view/Profile/PersonalDetails';
import PointHistoryReport from './view/AdminPortal/Report/PointHistoryReport';
import PointShopManagement from './view/AdminPortal/Settings/PointShopManagement';
import PointShopPage from './view/Home/PointShop';
import ProfilePage from './view/Profile/ProfilePage';
import RatingReport from './view/AdminPortal/Report/RatingReport';
import ReceiptValidation from './view/AdminPortal/Validation/ReceiptValidation';
import ReferAFriendPage from './view/Profile/ReferAFriend';
// import PreLoginPage from "./view/Register/PreLogin";
import Register from './view/Home/Register';
// import RetailerHomePage from './view/Retailer/RetailerHome';
// import RetailerQRCodePage from './view/Retailer/RetailerQRCode';
// import RetailerStatementPage from './view/Retailer/QuarterlyStatement';
import Rewards from './view/Home/Rewards';
import RewardsItem from './view/Home/RewardItems';
// import SalesRepHomePage from './view/SalesRep/SalesRepHome';
// import SalesRepInstructionPage from './view/SalesRep/SalesRepInstruction';
// import SalesRepScanQRPage from './view/SalesRep/SalesRepScanQR';
// import SalesRepStoreDetailsPage from './view/SalesRep/SalesRepStoreDetails';
// import SalesRepStoreSummaryPage from './view/SalesRep/SalesRepSummary';
// import SalesRepStoreUploadPage from './view/SalesRep/SalesRepUpload';
// import SideBarData from './components/AdminSideBar/SideBarData';
import StoreLocatorPage from './view/Home/StoreLocator';
import StorePerformanceReport from './view/AdminPortal/Report/StorePerformanceReport';
import StoreReport from './view/AdminPortal/Report/StoreReport';
import Submissions from './view/Home/Submissions';
import TieringPage from './view/Profile/TieringPage';
import UpdateAddressPage from './view/Profile/UpdateAddress';
import Upload from './view/Home/UploadReceipt';
import UserReport from './view/AdminPortal/Report/UserReport';
import VoucherManagement from './view/AdminPortal/Settings/VoucherManagement';
import WelcomePage from './view/Onboarding/Welcome';
import Whatsapp from './components/Whatsapp';
import AuthLoader from './view/Register/AuthLoader';
import { clearError } from './reducer/userDetailsReducer';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SalesRepRegisterStore from './view/SalesRep/SalesRepRegisterStore/SalesRepRegisterStore';
import SalesRepLayout from './components/SalesRepLayout/SalesRepLayout';
import SalesRepEditStore from './view/SalesRep/SalesRepEditStore/SalesRepEditStore';
import Badges from './view/Home/Badges';
import ContactPreference from './view/Profile/ContactPreference';
import i18n from './utilities/translation';
import Language from './view/Profile/Language';
const packageJson = require('../package.json');
function App() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	// const navigate = useNavigate();
	const location = window.location.pathname;
	const isSGRegion =
		window.location.hostname.replace('www.', '') ===
		process.env.REACT_APP_SG_DOMAIN;
	useEffect(() => {
		dispatch(clearError());
	}, [dispatch, location]);

	const isDev = process.env.REACT_APP_ENV === 'dev';
	const isShopper = !window?.location?.pathname?.includes(
		'admin' || 'operator' || 'retailer'
	);

	// remove existing user-token localStorage and use proper key: HALEON-REWARDS_USER_TOKEN
	localStorage.removeItem('user-token');

	useEffect(() => {
		if (
			isSGRegion &&
			(window.location.pathname === '/pointshop' ||
				window.location.pathname === '/cartInfo' ||
				window.location.pathname === '/cartSummary')
		) {
			navigate('/rewards', window.location.search);
		} else if (
			(isSGRegion && window.location.pathname === '/contact-preference') ||
			window.location.pathname === '/language'
		) {
			navigate('/profile', window.location.search);
		}
	}, [isSGRegion]);

	const [language, setLanguage] = useState(
		localStorage.getItem('HALEON-REWARDS_LANG') || 'en'
	);

	useEffect(() => {
		if (!isSGRegion) {
			const storedLanguage = localStorage.getItem('HALEON-REWARDS_LANG');
			if (storedLanguage) {
				setLanguage(storedLanguage);
				i18n.changeLanguage(storedLanguage);
			} else {
				localStorage.setItem('HALEON-REWARDS_LANG', 'en');
				i18n.changeLanguage('en');
			}
		} else {
			localStorage.setItem('HALEON-REWARDS_LANG', 'en');
			i18n.changeLanguage('en');
		}
	}, [isSGRegion]);

	return (
		<div>
			{isDev && (
				<p
					style={{
						textAlign: 'center',
						color: 'orange',
						background: 'black',
						position: 'fixed',
						left: '50%',
						zIndex: 10000,
						padding: '2px 4px',
						pointerEvents: 'none',
						cursor: 'default',
					}}
				>
					v-{packageJson.version}
				</p>
			)}
			{isShopper ? (
				<div className="App">
					<Whatsapp />
					<Routes>
						{/* <Route exact path="/prelogin" element={<PreLoginPage t={t} />} /> */}
						<Route
							exact
							path="/"
							element={
								<AuthRedirect role={USER}>
									<Home t={t} />{' '}
								</AuthRedirect>
							}
						/>
						<Route exact path="/auth/:token?" element={<AuthLoader />} />
						<Route exact path="/login" element={<LoginPage t={t} />} />
						<Route exact path="/register" element={<Register t={t} />} />
						<Route exact path="/loading" element={<LoadingPage t={t} />} />
						<Route exact path="/welcome" element={<WelcomePage t={t} />} />
						<Route
							exact
							path="/getStarted"
							element={<GetStartedPage t={t} />}
						/>
						<Route exact path="/verify-otp" element={<LoginVerify t={t} />} />
						<Route
							exact
							path="/upload/:token?"
							element={
								<AuthRedirect role={USER}>
									<Upload t={t} />{' '}
								</AuthRedirect>
							}
						/>
						<Route
							exact
							path="/home"
							element={
								<AuthRedirect role={USER}>
									<Home t={t} />{' '}
								</AuthRedirect>
							}
						/>
						<Route
							exact
							path="/article"
							element={
								<AuthRedirect role={USER}>
									<ArticlePage t={t} />{' '}
								</AuthRedirect>
							}
						/>
						<Route
							exact
							path="/storeLocator"
							element={
								<AuthRedirect role={USER}>
									<StoreLocatorPage t={t} />{' '}
								</AuthRedirect>
							}
						/>
						<Route
							exact
							path="/badges"
							element={
								<AuthRedirect role={USER}>
									<Badges t={t} />{' '}
								</AuthRedirect>
							}
						/>
						{/* <Route
							exact
							path="/stores"
							element={
								<AuthRedirect role={USER}>
									<ParticipatingStoresPage t={t} />{' '}
								</AuthRedirect>
							}
						/> */}
						<Route
							exact
							path="/rewards/:token?"
							element={
								<AuthRedirect role={USER}>
									<Rewards t={t} />{' '}
								</AuthRedirect>
							}
						/>
						{
							<Route
								exact
								path="/pointshop"
								element={
									<AuthRedirect role={USER}>
										<PointShopPage t={t} />{' '}
									</AuthRedirect>
								}
							/>
						}
						<Route
							exact
							path="/rewards/items/:id"
							element={
								<AuthRedirect role={USER}>
									<RewardsItem t={t} />{' '}
								</AuthRedirect>
							}
						/>
						<Route
							exact
							path="/submissions/:token?"
							element={
								<AuthRedirect role={USER}>
									<Submissions t={t} />{' '}
								</AuthRedirect>
							}
						/>
						<Route
							exact
							path="/profile"
							element={
								<AuthRedirect role={USER}>
									<ProfilePage t={t} />{' '}
								</AuthRedirect>
							}
						/>
						<Route
							exact
							path="/personal/details"
							element={
								<AuthRedirect role={USER}>
									<PersonalDetails t={t} />{' '}
								</AuthRedirect>
							}
						/>

						<Route
							exact
							path="/referFriend"
							element={
								<AuthRedirect role={USER}>
									<ReferAFriendPage t={t} />{' '}
								</AuthRedirect>
							}
						/>
						<Route
							exact
							path="/tiering"
							element={
								<AuthRedirect role={USER}>
									<TieringPage t={t} />{' '}
								</AuthRedirect>
							}
						/>
						{!isSGRegion && (
							<Route
								exact
								path="/language"
								element={
									<AuthRedirect role={USER}>
										<Language t={t} />{' '}
									</AuthRedirect>
								}
							/>
						)}

						{/* <Route exact path="/language" element={Auth <LanguagePage t={t} /> } /> */}
						{/* <Route exact path="/explorerBadge" element={Auth <ExplorerBadgePage t={t} /> } /> */}

						{!isSGRegion && (
							<Route
								exact
								path="/contact-preference"
								element={
									<AuthRedirect role={USER}>
										<ContactPreference t={t} />{' '}
									</AuthRedirect>
								}
							/>
						)}
						<Route
							exact
							path="/help/:token?"
							element={
								<AuthRedirect role={USER}>
									<HelpCenter t={t} />{' '}
								</AuthRedirect>
							}
						/>
						<Route
							exact
							path="/address"
							element={
								<AuthRedirect role={USER}>
									<AddressMainPage t={t} />{' '}
								</AuthRedirect>
							}
						/>
						<Route
							exact
							path="/add/address"
							element={
								<AuthRedirect role={USER}>
									<AddUpdateAddress t={t} />{' '}
								</AuthRedirect>
							}
						/>
						<Route
							exact
							path="/update/address"
							element={
								<AuthRedirect role={USER}>
									<UpdateAddressPage t={t} />{' '}
								</AuthRedirect>
							}
						/>

						<Route
							exact
							path="/cartInfo"
							element={
								<AuthRedirect role={USER}>
									<CartInfoPage t={t} />{' '}
								</AuthRedirect>
							}
						/>

						<Route
							exact
							path="/deliveryAddress"
							element={
								<AuthRedirect role={USER}>
									<DeliveryAddressPage t={t} />{' '}
								</AuthRedirect>
							}
						/>

						<Route
							exact
							path="/cartSummary"
							element={
								<AuthRedirect role={USER}>
									<CartSummaryPage t={t} />{' '}
								</AuthRedirect>
							}
						/>

						<Route
							exact
							path="/cartSuccess"
							element={
								<AuthRedirect role={USER}>
									<CartSuccessPage t={t} />{' '}
								</AuthRedirect>
							}
						/>

						<Route
							exact
							path="/"
							element={
								<AuthRedirect role={USER}>
									<LoadingPage t={t} />{' '}
								</AuthRedirect>
							}
						/>
					</Routes>
				</div>
			) : null}
		</div>
	);
}

export default App;
