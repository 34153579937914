import { useEffect, useState } from "react";
import { useNavigate, useParams,useLocation } from "react-router-dom";
import axiosClient from '../utils/axiosClient';
import { Buffer } from 'buffer';
import {
	ADMIN,
	PROMOTER,
	RETAILER,
	SALE_REP,
	USER,
	INTERNAL_USER,
} from '../enums';

const AuthRedirect = ({ children, role }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const [redirectTo, setRedirectTo] = useState();
	const { token } = useParams();
  
	useEffect(() => {
	  async function fetchData(token) {
		const res = await axiosClient.get('/users/details', {
		  headers: {
			Authorization: `Bearer ${token}`,
		  },
		});
		const path = location.pathname;
		const data = res.data;
		if (data.success && data) {
		  localStorage.setItem('HALEON-REWARDS_USER_TOKEN', token)
		  
		  if (path.startsWith('/help/')) {
			console.log(path)
			navigate('/help/', { replace: true });
		  }
		  if (path.startsWith('/upload/')) {
			console.log(path)
			navigate('/upload/', { replace: true });
		  }
		  if (path.startsWith('/rewards/')) {
			console.log(path)
			navigate('/rewards/', { replace: true });
		  }
		  if (path.startsWith('/submissions/')) {
			console.log(path)
			navigate('/submissions/', { replace: true });
		  }
		} else {
		  navigate('/', { replace: true });
		}
	  }
	  if (token) {
		const decodeToken = Buffer.from(token, 'base64').toString('utf-8');
		fetchData(decodeToken);
	  }
	});

	const checkTokenAndRedirectToRelativeLogin = () => {
		const token = localStorage.getItem('HALEON-REWARDS_USER_TOKEN');

		if (!token) {
			// Redirect to the corresponding login page
			switch (role) {
				// case ADMIN:
				// 	setRedirectTo('/admin/login');
				// 	break;
				// case USER:
				// 	// setRedirectTo('/login');
				// 	break;
				// case SALE_REP:
				// case RETAILER:
				// case PROMOTER:
				// 	setRedirectTo('/operator/login');
				// 	break;
				default:
					setRedirectTo('/'+window.location.search);
					break;
			}
		}
	};

	useEffect(() => {
		if (redirectTo && !token) {
			navigate(redirectTo);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redirectTo]);

	useEffect(() => {
		if (!token) {
			checkTokenAndRedirectToRelativeLogin();	
		}
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return children;
};

export default AuthRedirect;
